:root {
  color-scheme: var(--chakra-ui-color-mode)
}

.blink {
  animation: blink 1s infinite ease-in-out;
}

@keyframes blink {
  0% { opacity: 1 }
  50% { opacity: 0.7 }
  100% { opacity: 1 }
}
